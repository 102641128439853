
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { IProduct } from "@/types/product";
import {
  ICustomerProduct,
  ICustomerProductCreateRequest,
  ICustomerProductRequest,
  ICustomerProductUpdateRequest
} from "@/types/customer_product";
import { CustomerProductRequest } from "@/models/customer_product";
import { ICustomer, ICustomerListRequest } from "@/types/customer";

@Component({})
export default class extends Vue {
  //customer_product
  @Action("customer_product/adminGet")
  public getCustomerProduct!: (customer_product_id: number) => void;

  @Action("customer_product/adminCreate")
  public create!: (params: ICustomerProductCreateRequest) => boolean;

  @Action("customer_product/adminUpdate")
  public update!: (data: {
    customer_product_id: number;
    params: ICustomerProductUpdateRequest;
  }) => boolean;

  @Action("customer_product/adminDelete")
  public deleteCustomerProduct!: (customer_product_id: number) => boolean;

  @Action("customer_product/adminIsUniqueCustomerPN")
  public isUniqueCustomerPN!: (params: ICustomerProductRequest) => boolean;

  @Getter("customer_product/single")
  public customer_product!: ICustomerProduct;

  @Mutation("customer_product/clear")
  public clear!: () => void;

  //product
  @Action("product/adminGet")
  public getProduct!: (product_id: number) => void;

  @Getter("product/single")
  public product!: IProduct;

  @Mutation("product/clear")
  public clearProduct!: () => void;

  //customer
  @Action("customer/adminGetList")
  public getCustomers!: (params: ICustomerListRequest) => boolean;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //パラメータ定義
  public params: ICustomerProductRequest = new CustomerProductRequest();

  //変数定義
  public customer_product_id = 0;
  public product_id = 0;
  public product_name = "";
  public maker_name = "";
  public code = "";
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public customer_pn_errors: string[] = [];

  //ルール設定
  public rules = {
    customer_pn: [(v: string) => !!v || "顧客品番は必須です"],
    customer_id: [(v: number) => !!v || "顧客は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clear();
    this.product_id = Number(this.$route.params.product_id) || 0;
    this.customer_product_id =
      Number(this.$route.params.customer_product_id) || 0;

    await this.getCustomers({ per_page: 0 });

    //新規時
    if (this.product_id) {
      await this.getProduct(this.product_id);
      this.setCreateDefault();
    }

    // 編集時
    if (this.customer_product_id) {
      await this.getCustomerProduct(this.customer_product_id);
      this.setUpdateDefault();
    }

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  //新規時デフォルト値をセットする;
  private async setCreateDefault() {
    this.params.product_id = this.product.id;
    this.maker_name = this.product.maker_name;
    this.product_name = this.product.name;
    this.code = this.product.code;
  }

  //--------
  //更新時デフォルト値をセットする;
  private async setUpdateDefault() {
    this.params.product_id = this.customer_product.product_id;
    this.params.customer_id = this.customer_product.customer_id;
    this.params.customer_pn = this.customer_product.customer_pn;
    this.maker_name = this.customer_product.maker_name;
    this.product_name = this.customer_product.product_name;
    this.code = this.customer_product.product_code;
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.customer_product_id) {
      result = await this.update({
        customer_product_id: this.customer_product_id,
        params: { ...this.params, id: this.customer_product.id }
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteCustomerProduct(this.customer_product_id)) {
      this.$router.go(-1);
    }
  }

  // 顧客品番の二重登録チェック
  public async customer_pn_validate() {
    // 登録時
    if (
      !(await this.isUniqueCustomerPN(this.params)) &&
      !this.customer_product.customer_pn
    ) {
      return (this.customer_pn_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueCustomerPN(this.params)) &&
      this.customer_product.customer_pn != this.params.customer_pn
    ) {
      return (this.customer_pn_errors = ["既に使用されています"]);
    }

    return (this.customer_pn_errors = []);
  }
}
